<template>
  <div>
    <b-button
      v-b-modal.modal-create-fir
      variant="outline-primary"
      @click="initialSetup"
    >
      Create New
    </b-button>

    <!-- select 2 demo -->
    <b-modal
      id="modal-create-fir"
      title="Create New FIR Tracker"
      no-close-on-backdrop
      centered
      ok-title="submit"
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        <b-overlay
          :show="$store.state.firTrackerStore.isNewFIRLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <validation-observer ref="matterForm" #default="{ invalid }">
            <b-form @submit.prevent="fetchFIR">
              <!-- Select Court -->
              <validation-provider
                #default="{ errors }"
                name="Court"
                vid="court"
                rules="required"
              >
                <b-form-group label="Select Court" label-for="court">
                  <v-select
                    v-model="CourtSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="CourtOption"
                    label="text"
                    placeholder="Select Court"
                    @input="onCourtSelect"
                  />
                </b-form-group>
              </validation-provider>
              <!-- Normal Court -->
              <!-- Select Case Type -->
              <div
                v-if="
                  CourtSelected != null &&
                  (CourtSelected.unique_id.includes('hc-allahabad') ||
                    CourtSelected.unique_id.includes('hc-lucknow'))
                "
                class="demo-inline-spacing mb-1"
              >
                <b-form-radio
                  v-model="isCrimeCaseNoType"
                  name="some-radios"
                  class="mt-0"
                  :value="true"
                >
                  Crime Number
                </b-form-radio>
                <b-form-radio
                  v-model="isCrimeCaseNoType"
                  name="some-radios"
                  class="mt-0"
                  :value="false"
                >
                  ST Number
                </b-form-radio>
              </div>

              <div class="row">
                <!-- Case Number -->

                <div
                  class="col-6"
                  v-if="
                    CourtSelected != null &&
                    CourtSelected.unique_id.includes('hc-punjab')
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="'FIR Number'"
                    vid="case-number"
                    rules="required"
                  >
                    <b-form-group label-for="case-number" label="FIR Number">
                      <b-form-input
                        id="case-number"
                        rules="required"
                        v-model="CaseNumber"
                        placeholder="Enter FIR Number"
                        @input="onCaseNumberChange"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>

                <div class="col-6" v-else>
                  <validation-provider
                    #default="{ errors }"
                    :name="'Case Number'"
                    vid="case-number"
                    rules="required"
                  >
                    <b-form-group label-for="case-number" label="Case Number">
                      <b-form-input
                        id="case-number"
                        rules="required"
                        v-model="CaseNumber"
                        placeholder="Enter Case Number"
                        @input="onCaseNumberChange"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>

                <!-- Select Year -->
                <div
                  class="col-6"
                  v-if="
                    CourtSelected != null &&
                    CourtSelected.unique_id.includes('hc-punjab')
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="FIR Date"
                    vid="year"
                    rules="required"
                  >
                    <b-form-group label="FIR Date" label-for="year">
                      <b-form-datepicker
                        id="start-datepicker"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @input="onYearSelect"
                        rules="required"
                        v-model="YearSelected"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="col-6" v-else>
                  <validation-provider
                    #default="{ errors }"
                    name="Year"
                    vid="year"
                    rules="required"
                  >
                    <b-form-group label="Select Year" label-for="year">
                      <v-select
                        v-model="YearSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="YearOption"
                        rules="required"
                        placeholder="Select Year"
                        @input="onYearSelect"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <validation-provider
                    #default="{ errors }"
                    name="District"
                    vid="district"
                    rules="required"
                  >
                    <b-form-group label="Select District" label-for="district">
                      <v-select
                        v-model="DistrictSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="DistrictOptions"
                        label="title"
                        placeholder="Select District"
                        @input="onDistrictSelect"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
                <div
                  class="col-6"
                  v-if="
                    isCrimeCaseNoType &&
                    CourtSelected != null &&
                    !CourtSelected.unique_id.includes('hc-punjab')
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Police Station"
                    vid="police-station"
                    :rules="
                      isCrimeCaseNoType &&
                      CourtSelected != null &&
                      CourtSelected.unique_id.includes('hc-punjab')
                        ? 'required'
                        : ''
                    "
                  >
                    <b-form-group
                      label="Select Police Station"
                      label-for="police-station"
                    >
                      <v-select
                        v-model="PoliceStationSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="PoliceStationOptions"
                        label="police_st_name"
                        placeholder="Select Police Station"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </b-form>
          </validation-observer>
          <template #overlay>
            <div class="text-center">
              <b-spinner variant="secondary"></b-spinner>
              <p id="cancel-label">{{ loadingMsg }}</p>
            </div>
          </template>
        </b-overlay>
      </b-card-text>

      <!-- Footer -->
      <template #modal-footer>
        <!-- By Matter Footer -->
        <div class="w-100">
          <div>
            <div>
              <b-button
                variant="primary"
                class="float-right"
                type="submit1"
                :disabled="$store.state.firTrackerStore.isNewFIRLoading"
                @click="fetchFIR"
              >
                Submit
              </b-button>
            </div>
            <div>
              <b-button
                variant="outline-dark"
                class="float-right mr-1 ml-1"
                type="cancel"
                @click="$bvModal.hide('modal-create-fir')"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BOverlay,
  BFormRadio,
  BSpinner,
  BFormInvalidFeedback,
  BFormDatepicker,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BCardText,
    BFormInvalidFeedback,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    BFormDatepicker,
    BListGroup,
    BListGroupItem
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  data() {
    return {
      // Matter
      Title: null,
      CourtSelected: null,
      CourtOption: null,
      OtherCaseType: null,
      CaseSelected: null,
      HearingDate: null,
      CaseOption: [],
      isCrimeCaseNoType: true,
      CaseNumber: null,
      YearSelected: null,
      YearOption: [],
      DistrictOptions: [],
      DistrictSelected: null,
      PoliceStationOptions: [],
      PoliceStationSelected: null,

      // Data
      CurrentMatter: null,
      loadingMsg: '',
      multipleCases: [],

      // validation rules
      required
    }
  },
  props: ['refreshList'],
  methods: {
    getCourts() {
      let allC = []
      const courtsList = []
      // ** Court Setup Starts
      const ll = this.$store.state.courtStore.courtsData
      console.log({ SDS: ll })
      if (ll) {
        const hcl = ll.highCourts
          .filter(function (key) {
            if (Object.keys(key).includes('fir_section_enabled')) {
              return key.enabled && key.fir_section_enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const hc = {
          label: 'High Court',
          options: hcl
        }
        const dcl = ll.districtCourts
          .filter(function (key) {
            if (Object.keys(key).includes('fir_section_enabled')) {
              return key.enabled && key.fir_section_enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const dc = {
          label: 'District Court',
          options: dcl
        }

        courtsList.push(hc)
        courtsList.push(dc)
        allC = [...hcl, ...dcl]
      }
      this.CourtOption = allC
    },
    initialSetup() {
      const years = []
      for (var i = new Date().getFullYear(); i >= 1901; i--) {
        years.push(i)
      }
      this.years = years

      this.CourtSelected = null
      this.CaseNumber = null
      this.YearOption = years
      this.YearSelected = null
      this.DistrictOptions = []
      this.PoliceStationOptions = []
      this.isCrimeCaseNoType = true
      this.DistrictSelected = null
      this.PoliceStationSelected = null
    },
    clearAllFields() {
      // Matter
      ;(this.Title = null),
        (this.CourtSelected = null),
        (this.OtherCaseType = null),
        (this.CaseSelected = null),
        (this.CaseOption = []),
        (this.CaseNumber = null),
        (this.isCrimeCaseNoType = true),
        (this.CurrentMatter = null),
        (this.YearSelected = null),
        (this.DistrictOptions = []),
        (this.PoliceStationOptions = []),
        (this.DistrictSelected = null),
        (this.PoliceStationSelected = null)
    },
    // Matters
    onCourtSelect(event) {
      ;(this.CaseSelected = null),
        (this.CaseOption = []),
        (this.YearSelected = null),
        (this.Title = null),
        (this.isCrimeCaseNoType = true),
        (this.DistrictOptions = []),
        (this.PoliceStationOptions = []),
        (this.DistrictSelected = null),
        (this.PoliceStationSelected = null)
      console.log(event)
      this.$store
        .dispatch(
          'firTrackerStore/fetchFIRDistrict',
          this.CourtSelected.unique_id
        )
        .then((response) => {
          console.log('Case Types --> ', response)
          if (response) {
            if (response.success) {
              this.DistrictOptions = response.data.reverse()
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onDistrictSelect(event) {
      ;(this.PoliceStationOptions = event.police_stations),
        (this.PoliceStationSelected = null)
      console.log(event)
    },
    onYearSelect(event) {},
    onCaseNumberChange(event) {},
    fetchFIR() {
      this.loadingMsg = 'Please Wait, Connecting to the Court!'
      this.CurrentMatter = null
      event.preventDefault()
      this.$refs.matterForm.validate().then((success) => {
        if (success) {
          let data
          if (this.CourtSelected.unique_id.includes('hc-punjab')) {
            data = {
              court_id: this.CourtSelected.value,
              crime_type: 'crime_number',
              crime_number: String(this.CaseNumber),
              crime_year: String(this.YearSelected),
              district: String(this.DistrictSelected.metadata.dist)
            }
          } else if (this.isCrimeCaseNoType) {
            data = {
              court_id: this.CourtSelected.value,
              crime_type: 'crime_number',
              crime_number: String(this.CaseNumber),
              crime_year: String(this.YearSelected),
              police_station: String(this.PoliceStationSelected.police_st_code),
              district: String(this.DistrictSelected.metadata.dist)
            }
          } else {
            data = {
              court_id: this.CourtSelected.value,
              crime_type: 'st_number',
              crime_number: String(this.CaseNumber),
              crime_year: String(this.YearSelected),
              district: String(this.DistrictSelected.metadata.dist)
            }
          }
          console.log('Data C', data)
          store
            .dispatch('firTrackerStore/findFIR', {
              ctx: this,
              payload: data
            })
            .then((response) => {
              console.log({ Matter: response })
              if (response != null) {
                if (response.success) {
                  this.refreshList()
                }
              }
            })
        }
      })
    }
  },
  beforeMount() {
    this.getCourts()
  },
  setup(props, context) {
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
